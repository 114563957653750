import React, { FC } from 'react';
import { RelatedProductsTitleProps } from './model';

const RelatedProductsTitle: FC<RelatedProductsTitleProps> = ({ title }) => {
  return (
    <div className="related-product__title">
      <h3>{title}</h3>
    </div>
  );
};

export default RelatedProductsTitle;
