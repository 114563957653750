import React, { FC, useState, useEffect, useMemo } from 'react';
import { graphql } from 'gatsby';

import { Container, Layout, Row, Col } from 'gatsby-theme-husky/src/layout';
import BodyRenderer from 'gatsby-theme-husky/src/common/BodyRenderer';
import ControlledCarousel from 'gatsby-theme-husky/src/common/ControlledCarousel';
import FullWidthPromo from 'gatsby-theme-husky/src/components/FullWidthPromo';
import ReviewsPanel from 'gatsby-theme-husky/src/components/ReviewsPanel';
import TwoColumnPromo from 'gatsby-theme-husky/src/components/TwoColumnPromo';
import RelatedArticles from 'gatsby-theme-husky/src/components/RelatedArticles';
import RelatedProducts from 'gatsby-theme-husky/src/components/RelatedProducts';
import breakpoints from 'gatsby-theme-husky/src/components/RelatedArticles/constants';
import { IRelatedProductsProps } from 'gatsby-theme-husky/src/components/RelatedProducts/models';
import HeroBanner from 'gatsby-theme-husky/src/components/ArticleListingHeroBanner';

import responsiveObj from './constants';
import { HomePageComponentPropsType } from './models';
import './HomePageMain.scss';
import HomeCarousel from '../../components/HomeCarousel';

const WithContainer = ({ children }) => (
  <Container className="home-page__body-container">
    <Row>
      <Col sm="12" md="12" lg="12" xl="12">
        {children}
      </Col>
    </Row>
  </Container>
);

const elements = {
  'Full Width Promo': ({ properties: { title, cta, bodyColor, body } }, keyId) => (
    <WithContainer key={keyId}>
      <FullWidthPromo title={title} cta={cta} bodyColor={bodyColor?.[0]} body={body} />
    </WithContainer>
  ),
  'Two Column Promo': ({ properties: { promoRows } }, keyId) => (
    <TwoColumnPromo key={keyId} rows={promoRows} inFirstViewport={false} />
  ),
  'Related articles': (
    {
      properties: { relatedArticles, relatedArticlesTitle, relatedArticlesReadMore, articleLinks },
    },
    keyId
  ) => (
    <Container className="home-page__body-container" key={keyId}>
      <RelatedArticles
        breakpoints={breakpoints}
        data={
          articleLinks
            ? relatedArticles.nodes.filter(({ url }) => articleLinks.includes(url))
            : relatedArticles.nodes
        }
        title={relatedArticlesTitle}
        readMoreText={relatedArticlesReadMore}
        ariaLabel={relatedArticlesReadMore}
        articlesCount={3}
      />
    </Container>
  ),
  'Related Products': ({ properties: { relatedProducts, productLinks, ...restProps } }, keyId) => (
    <Container className="home-page__body-container" key={keyId}>
      <RelatedProducts
        relatedProducts={
          productLinks
            ? { nodes: relatedProducts.nodes.filter(({ url }) => productLinks.includes(url)) }
            : relatedProducts
        }
        {...(restProps as Omit<IRelatedProductsProps, 'relatedProducts'>)}
      />
    </Container>
  ),
};

const ReviewsPanelRenderer = (
  {
    properties: { yotpoReviewsCarouselCode, yotpoReviewsCarouselTitle, yotpoReviewsCarouselButton },
  },
  keyId
) => (
  <ReviewsPanel
    key={keyId}
    yotpoReviewsCarouselTitle={yotpoReviewsCarouselTitle}
    yotpoReviewsCarouselCode={yotpoReviewsCarouselCode}
    yotpoReviewsCarouselButton={yotpoReviewsCarouselButton}
  />
);

const HomePage: FC<HomePageComponentPropsType> = ({
  data: {
    umbracoHome: {
      seoMetaTitle,
      seoMetaDescription,
      seoMetaKeywords,
      seoAdditionalMeta,
      seoExternalHreflangs,
      defaultCompositions,
      hpCarousel,
      hpCarousel: [
        {
          properties: {
            image: { gatsbyImage },
            imageAlt,
            title,
            button,
          },
        },
      ],
      homepageBlocks,
    },
    relatedArticles,
    relatedProducts,
  },
}) => {
  const [bodyStructure, setBodyStructure] = useState(elements);

  const addReviewsPanelBlock = useMemo(
    () => () => {
      const updatedElements = {
        ...bodyStructure,
        'Reviews Carousel': ReviewsPanelRenderer,
      } as typeof elements;

      setBodyStructure(updatedElements);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      removeReviewsPanelBlockEventListener();
    },
    []
  );

  const addReviewsPanelBlockEventListener = useMemo(
    () => () => {
      document.addEventListener('touchmove', addReviewsPanelBlock, false);
      document.addEventListener('scroll', addReviewsPanelBlock, false);
    },
    [addReviewsPanelBlock]
  );

  const removeReviewsPanelBlockEventListener = useMemo(
    () => () => {
      document.removeEventListener('touchmove', addReviewsPanelBlock);
      document.removeEventListener('scroll', addReviewsPanelBlock);
    },
    [addReviewsPanelBlock]
  );

  useEffect(() => {
    addReviewsPanelBlockEventListener();

    return removeReviewsPanelBlockEventListener;
  }, [addReviewsPanelBlock]);

  return (
    <Layout
      defaultCompositions={defaultCompositions}
      headerTransparent
      seo={{
        seoMetaDescription,
        seoMetaKeywords,
        seoMetaTitle,
        seoExternalHreflangs,
        seoAdditionalMeta,
      }}
    >
      <div className="home-page">
        {hpCarousel.length === 1 ? (
          <HeroBanner
            inFirstViewport
            image={gatsbyImage}
            altImageText={imageAlt}
            title={title}
            hasVerticalPadding={false}
            button={button}
            className="article-listing-hero-banner"
          />
        ) : (
          <ControlledCarousel
            showDots
            infinite
            showNavigators
            classes="hp-carousel-carousel"
            responsiveObj={responsiveObj}
          >
            {hpCarousel.map(({ properties }) => (
              <HomeCarousel key={properties} properties={properties} />
            ))}
          </ControlledCarousel>
        )}
        {homepageBlocks?.length > 0 ? (
          <BodyRenderer
            bodyData={homepageBlocks}
            bodyStructure={bodyStructure}
            bodyItemProps={{ relatedArticles, relatedProducts }}
          />
        ) : null}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($url: String, $relatedArticlesLinks: [String]) {
    umbracoHome(url: { eq: $url }) {
      seoMetaDescription
      seoMetaKeywords
      seoMetaTitle
      seoAdditionalMeta {
        key
        value
      }
      seoExternalHreflangs {
        hreflang: key
        href: value
      }
      defaultCompositions {
        ...DefaultCompositionsFragment
      }
      hpCarousel {
        properties {
          title
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageSm: image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          imageMd: image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          imageMobileBanner {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          backgroundImageBanner {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          productImageBanner {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageAlt
          button {
            properties {
              ariaLabel
              buttonText
              buttonLink {
                url
              }
            }
          }
          description
        }
      }
      homepageBlocks {
        structure
        properties {
          cta {
            properties {
              ariaLabel
              buttonColor
              buttonText
              buttonLink {
                url
                name
              }
            }
          }
          productLinks
          relatedProductsTitle
          relatedProductsCardCTAButtonAriaLabel
          relatedProductsCardCtaButton
          relatedProductsCtaButtonShow
          products {
            url
          }
          relatedProductsCtaButton {
            url
            name
          }
          articleLinks
          relatedArticlesReadMore
          relatedArticlesTitle
          title
          bodyColor
          body
          ...FragmentReviewsPanel
          promoRows {
            properties {
              countryCta {
                properties {
                  ariaLabel
                  buttonText
                  buttonLink {
                    name
                    url
                  }
                  cta {
                    name
                    url
                  }
                  country {
                    properties {
                      country
                    }
                  }
                }
              }
              title
              textBgColor
              bGVersion
              packshotBgColor
              button {
                properties {
                  ariaLabel
                  buttonText
                  buttonLink {
                    name
                    url
                  }
                }
              }
              description
              imageBackground {
                properties {
                  imageAltLabel
                  image {
                    ...FragmentGatsbyImageLg
                  }
                }
              }
              imagePackshot {
                properties {
                  imageAltLabel
                  image {
                    ...FragmentGatsbyImageSm
                  }
                }
              }
            }
          }
        }
      }
    }
    relatedProducts: allUmbracoProducts {
      nodes {
        url
        properties {
          image {
            properties {
              imageAltLabel
            }
            localImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          title
        }
      }
    }
    relatedArticles: allUmbracoArticles(filter: { url: { in: $relatedArticlesLinks } }) {
      nodes {
        id
        title
        properties {
          title
          articleBody {
            structure
            properties {
              articleIntroductionText
            }
          }
          articleHeroBannerMainImageAltText
        }
        url
        localImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default HomePage;
