import React, { FC } from 'react';
import Button from 'gatsby-theme-husky/src/common/Button';
import classNames from 'classnames';
import RelatedProductCard from 'gatsby-theme-husky/src/components/RelatedProductCard';
import DangerouslySetInnerHtml from 'gatsby-theme-husky/src/layout/DangerouslySetInnerHtml';
import RelatedProductsTitle from './RelatedProductsTitle';
import { IRelatedProductsProps } from './models';
import './RelatedProducts.scss';
import './RelatedProductsRTL.scss';

const RelatedProducts: FC<IRelatedProductsProps> = ({
  mainProduct,
  relatedProducts,
  relatedProductsCardCtaButton,
  relatedProductsCtaButtonShow,
  relatedProductsTitle,
  relatedProductsCardCTAButtonAriaLabel,
  relatedProductsCtaButton,
  relatedProductsDescription,
  relatedProductsBadge,
}) => {
  const isBrowser = typeof window !== 'undefined';
  let country: string;

  if (isBrowser) {
    country = localStorage.getItem('codeCountry') ?? '/pe/';
  }

  relatedProducts.nodes = relatedProducts.nodes.filter((product) => product.url.includes(country));

  return (
    <div
      className={classNames('related-product', {
        'related-product__without-button': relatedProductsCtaButtonShow !== '1',
      })}
    >
      {relatedProductsBadge ? (
        <div className="related-product__badge">
          <div className="related-product__badge--text">{relatedProductsBadge}</div>
        </div>
      ) : null}

      <RelatedProductsTitle title={relatedProductsTitle} />
      {relatedProductsDescription ? (
        <DangerouslySetInnerHtml
          html={relatedProductsDescription}
          className="related-product__description"
        />
      ) : null}

      {mainProduct && mainProduct?.nodes?.length > 0 ? (
        <div className="related-product__main-product">
          <RelatedProductCard
            key={mainProduct?.nodes[0]?.url}
            productCard={mainProduct?.nodes[0]}
            relatedProductsCardCtaButton={relatedProductsCardCtaButton}
          />
        </div>
      ) : null}
      <div className="related-product__cards-wrapper">
        {relatedProducts.nodes && Array.isArray(relatedProducts.nodes)
          ? relatedProducts.nodes.map((item) => (
              <RelatedProductCard
                key={item.url}
                productCard={item}
                relatedProductsCardCtaButton={relatedProductsCardCtaButton}
              />
            ))
          : null}
      </div>

      {relatedProductsCtaButtonShow === '1' ? (
        <div className="related-product__button">
          <Button
            classes="button--outline-dark"
            link={relatedProductsCtaButton?.[0].url}
            ariaLabel={relatedProductsCardCTAButtonAriaLabel || relatedProductsCtaButton?.[0].name}
          >
            {relatedProductsCtaButton?.[0].name}
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default RelatedProducts;
