import React, { FC } from 'react';
import GatsbyImage from 'gatsby-theme-husky/src/common/GatsbyImage';
import useScreenRecognition from 'gatsby-theme-husky/src/hooks/useScreenRecognition';
import { IHomeCarousel } from './models';
import './HomeCarousel.scss';
import BannerTextSection from '../BannerTextSection';

const HomeCarousel: FC<IHomeCarousel> = ({
  properties: {
    backgroundImageBanner,
    button,
    description,
    imageAlt,
    imageMobileBanner,
    productImageBanner,
    title,
    url,
  },
}) => {
  const { isMobile } = useScreenRecognition();

  return (
    <div className="hero-banner">
      {isMobile ? (
        <div>
          <GatsbyImage
            isLazyLoading
            fluid={imageMobileBanner.gatsbyImage.childImageSharp.fluid}
            alt={imageAlt}
            fadeIn
          />
          <BannerTextSection button={button} description={description} title={title} url={url} />
        </div>
      ) : (
        <>
          <div className="banner-text">
            {' '}
            <h1 className="banner-text__title">{title}</h1>
            <p className="banner-text__description">{description}</p>
          </div>
          <BannerTextSection button={button} description={description} title={title} url={url} />
          <div className="container__img">
            <GatsbyImage
              isLazyLoading
              fluid={backgroundImageBanner.gatsbyImage.childImageSharp.fluid}
              alt={imageAlt}
              fadeIn
              className={backgroundImageBanner ? 'background__img' : ''}
            />
            <GatsbyImage
              isLazyLoading
              fluid={productImageBanner.gatsbyImage.childImageSharp.fluid}
              alt={imageAlt}
              fadeIn
              className={productImageBanner ? 'product__img' : ''}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default HomeCarousel;
